(function () {
	'use strict';
	angular
		.module('event')
		.controller('SelectCtrl', ['$scope', '$timeout', SelectCtrl]);

	function SelectCtrl($scope, $timeout) {
		//Initialize any initial states
		var initType = $scope.edit.selectedPanelItem;

		var contactData;
		var projectData;
		var resourceData;
		if (
			$scope.edit.schedule.sourceTypeID === 4 ||
			$scope.edit.schedule.sourceTypeID === 10
		) {
			// SalesForce
			initializeState(initType);
		} else if ($scope.edit.schedule.sourceTypeID === 8) {
			// FileMaker JS
			$scope.singleSelectItem = true;
			$scope.edit.secondPanel = true;
			if (initType === 'project') {
				projectData = $scope.edit.schedule.projectData;
				if ($scope.edit.projectName) {
					$scope.edit.projectObject = projectData.searchLayout;
					$scope.edit.projectObjectDisplay =
						$scope.edit.schedule.labelMap &&
						$scope.edit.schedule.labelMap.projectName
							? $scope.edit.schedule.labelMap.projectName
							: 'Project';
				}
				$scope.edit.projectSearch = projectData.searchField;
				$scope.edit.projectDisplay = projectData.displayField;
			} else if (initType === 'resource') {
				resourceData = $scope.edit.schedule.resourceData;
				$scope.edit.resourceObject = resourceData.searchLayout;
				$scope.edit.resourceObjectDisplay =
					$scope.edit.schedule.labelMap &&
					$scope.edit.schedule.labelMap.resource
						? $scope.edit.schedule.labelMap.resource
						: 'Resource';
				$scope.edit.resourceSearch = resourceData.searchField;
				$scope.edit.resourceDisplay = resourceData.displayField;
			} else {
				contactData = $scope.edit.schedule.contactData;
				if ($scope.edit.contactName) {
					$scope.edit.contactObject = contactData.searchLayout;
					$scope.edit.contactObjectDisplay =
						$scope.edit.schedule.labelMap &&
						$scope.edit.schedule.labelMap.contactName
							? $scope.edit.schedule.labelMap.contactName
							: 'Contact';
				}
				$scope.edit.contactSearch = contactData.searchField;
				$scope.edit.contactDisplay = contactData.displayField;
			}
		}

		$scope.selectContact = selectContact;

		function selectContact(e, id, Name, forceSingle) {
			$scope.onFieldSave(
				$scope.edit,
				'contact',
				id,
				Name,
				$scope.edit.contactID,
				$scope.edit.contactObject,
				update,
				e
			);

			function update(reject) {
				if (reject) {
					return;
				}
				$scope.$evalAsync(function () {
					$scope.edit.contactID = $scope.listClick(
						e,
						id,
						$scope.edit.contactID,
						true
					);
					$scope.edit.contactName = $scope.listClick(
						e,
						Name,
						$scope.edit.contactName,
						true
					);
					saveContactObjectData($scope.edit.contactName);
				});
			}
		}

		$scope.selectProject = selectProject;

		function selectProject(e, id, Name, forceSingle) {
			$scope.onFieldSave(
				$scope.edit,
				'project',
				id,
				Name,
				$scope.edit.projectID,
				$scope.edit.projectObject,
				update,
				e
			);

			function update(reject) {
				if (reject) {
					return;
				}
				$timeout(function () {
					$scope.edit.projectID = $scope.listClick(
						e,
						id,
						$scope.edit.projectID,
						true
					);
					$scope.edit.projectName = $scope.listClick(
						e,
						Name,
						$scope.edit.projectName,
						true
					);
					saveProjectObjectData($scope.edit.projectName);
				}, 0);
			}
		}

		$scope.selectResource = selectResource;

		function selectResource(e, id, Name, forceSingle) {
			$scope.onFieldSave(
				$scope.edit,
				'resource',
				id,
				Name,
				$scope.edit.resourceID,
				$scope.edit.resourceObject,
				update,
				e
			);

			function update(reject) {
				if (reject) {
					return;
				}
				$timeout(function () {
					$scope.edit.resourceID = $scope.listClick(
						e,
						id,
						$scope.edit.resourceID,
						true
					);
					$scope.edit.resource = $scope.listClick(
						e,
						Name,
						$scope.edit.resource,
						true
					);
					saveResourceObjectData();
				}, 0);
			}
		}

		$scope.objectToArray = objectToArray;

		$scope.saveContactObjectData = saveContactObjectData;

		$scope.clearSelection = clearSelection;

		$scope.navigateBack = navigateBack;

		$scope.saveProjectObjectData = saveProjectObjectData;

		$scope.saveResourceObjectData = saveResourceObjectData;

		$scope.contactObjectSelect = contactObjectSelect;

		$scope.projectObjectSelect = projectObjectSelect;

		$scope.resourceObjectSelect = resourceObjectSelect;

		function initializeState(type) {
			var adjustedType = type;
			if (adjustedType === 'relatedResource') {
				adjustedType = 'resource';
			}
			var obj = $scope.edit.schedule[adjustedType + 'Objects'];
			var isSingle = singleObjectItem(obj);
			var singleObject;
			if (isSingle) {
				$scope.singleSelectItem = true;
				for (var property in obj) {
					singleObject = obj[property];
					if (type === 'contact') {
						if ($scope.edit.contactName) {
							contactObjectSelect(
								null,
								singleObject.object,
								singleObject.objectDisplay,
								singleObject.searchField,
								singleObject.displayField,
								singleObject.defaultCriteria
							);
						}
					} else if (type === 'project') {
						projectObjectSelect(
							null,
							singleObject.object,
							singleObject.objectDisplay,
							singleObject.searchField,
							singleObject.displayField,
							singleObject.defaultCriteria
						);
					} else if (type === 'relatedResource') {
						resourceObjectSelect(
							null,
							singleObject.object,
							singleObject.objectDisplay,
							singleObject.searchField,
							singleObject.displayField,
							singleObject.defaultCriteria
						);
					}
				}
			}
		}

		function singleObjectItem(obj) {
			var objectLength = !obj ? 0 : Object.keys(obj).length;
			if (objectLength === 1) {
				return true;
			}
		}

		function objectToArray(o) {
			var i;
			var result = [];
			for (i in o) {
				result.push(o[i]);
			}

			function compare(a, b) {
				if (a.objectDisplay < b.objectDisplay) {
					return -1;
				} else if (a.objectDisplay > b.objectDisplay) {
					return 1;
				} else {
					return 0;
				}
			}

			return result.sort(compare);
		}

		function contactObjectSelect(
			e,
			object,
			objectDisplay,
			searchField,
			displayField,
			defaultCriteria
		) {
			$scope.edit.secondPanel = true;
			$scope.edit.contactObject = object;
			$scope.edit.contactSearch = searchField;
			$scope.edit.contactDisplay = displayField;
			$scope.edit.contactCriteria = defaultCriteria;
			$scope.edit.contactObjectDisplay = objectDisplay;
			if ($scope.edit.contactCriteria) {
				//create dummy event object
				e = {};
				e.keyCode = 13;
				e.preventDefault = function () {
					return true;
				};
				e.defaultCriteria = true;
				$scope.findItems(
					e,
					'contacts',
					object,
					searchField,
					displayField,
					$scope.edit.contactCriteria
				);
			}
		}

		function projectObjectSelect(
			e,
			object,
			objectDisplay,
			searchField,
			displayField,
			defaultCriteria
		) {
			$scope.edit.secondPanel = true;
			$scope.edit.projectObject = object;
			$scope.edit.projectSearch = searchField;
			$scope.edit.projectDisplay = displayField;
			$scope.edit.projectCriteria = defaultCriteria;
			$scope.edit.projectObjectDisplay = objectDisplay;
			if ($scope.edit.projectCriteria) {
				//create dummy event object
				e = {};
				e.keyCode = 13;
				e.preventDefault = function () {
					return true;
				};
				e.defaultCriteria = true;
				$scope.findItems(
					e,
					'projects',
					object,
					searchField,
					displayField,
					$scope.edit.projectCriteria
				);
			}
		}

		function resourceObjectSelect(
			e,
			object,
			objectDisplay,
			searchField,
			displayField,
			defaultCriteria
		) {
			$scope.edit.secondPanel = true;
			$scope.edit.resourceObject = object;
			$scope.edit.resourceObjectDisplay = objectDisplay;
			$scope.edit.resourceSearch = searchField;
			$scope.edit.resourceDisplay = displayField;
			$scope.edit.resourceCriteria = defaultCriteria;
			if ($scope.edit.resourceCriteria) {
				//create dummy event object
				e = {};
				e.keyCode = 13;
				e.preventDefault = function () {
					return true;
				};
				e.defaultCriteria = true;
				$scope.findItems(
					e,
					'resources',
					object,
					searchField,
					displayField,
					$scope.edit.resourceCriteria
				);
			}
		}

		function clearSelection(objectType, clearParentSelection) {
			$scope.edit[objectType + 'Name'] = [];
			$scope.edit[objectType + 'ID'] = [];
			if (clearParentSelection) {
				$scope.edit[objectType + 'Object'] = null;
			}
			setLabelMapOverride(objectType + 'Name', null);
		}

		function navigateBack(objectType) {
			if (!$scope.edit[objectType + 'Name']) {
				$scope.edit[objectType + 'Object'] = null;
			}
			$scope.edit[objectType + 'ObjectDisplay'] = null;
		}

		function saveContactObjectData(dataValue) {
			$scope.edit.event.contactObject = $scope.edit.contactObject;
			$scope.edit.event.contactObjectDisplay =
				$scope.edit.contactObjectDisplay;
			$scope.edit.event.contactSearch = $scope.edit.contactSearch;
			$scope.edit.event.contactDisplay = $scope.edit.contactDisplay;

			setLabelMapOverride(
				'contactName',
				$scope.edit.contactObjectDisplay,
				dataValue
			);
		}

		function saveProjectObjectData(dataValue) {
			$scope.edit.event.projectObject = $scope.edit.projectObject;
			$scope.edit.event.projectObjectDisplay =
				$scope.edit.projectObjectDisplay;
			$scope.edit.event.projectSearch = $scope.edit.projectSearch;
			$scope.edit.event.projectDisplay = $scope.edit.projectDisplay;

			setLabelMapOverride(
				'projectName',
				$scope.edit.projectObjectDisplay,
				dataValue
			);
		}

		function setLabelMapOverride(field, label, value) {
			var valueExists = value && value.length;
			// Set edit event object
			if (!$scope.edit.labelMapOverride && valueExists) {
				$scope.edit.labelMapOverride = {};
			}

			if ($scope.edit.labelMapOverride && !valueExists) {
				delete $scope.edit.labelMapOverride[field];
			} else if (valueExists) {
				$scope.edit.labelMapOverride[field] = label;
			}

			// Set event object data
			if (!$scope.edit.event.labelMapOverride && valueExists) {
				$scope.edit.event.labelMapOverride = {};
			}

			if ($scope.edit.event.labelMapOverride && !valueExists) {
				delete $scope.edit.event.labelMapOverride[field];
			} else if (valueExists) {
				$scope.edit.event.labelMapOverride[field] = label;
			}
		}

		function saveResourceObjectData() {
			$scope.edit.event.resourceObject = $scope.edit.resourceObject;
			$scope.edit.event.resourceObjectDisplay =
				$scope.edit.resourceObjectDisplay;
			$scope.edit.event.resourceSearch = $scope.edit.resourceSearch;
			$scope.edit.event.resourceDisplay = $scope.edit.resourceDisplay;
		}
	}
})();
